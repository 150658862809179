<template>
  <div class="main-view">
    <div class="section filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" size="small">
        <el-form-item prop="account">
          <el-input v-model.trim="table.params.account" placeholder="反馈账号">
            <i slot="suffix" class="el-input__icon el-icon-search" @click="onSearch"></i>
          </el-input>
        </el-form-item>
        <el-form-item prop="school_id">
          <el-select v-model="table.params.school_id" filterable placeholder="请输入学校名称" @focus="getSchool" @change="onSearch">
            <el-option v-for="item in schoolOpt" :label="item.name" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    
    <VTable
      has-pagionation
      title=''
      addText='添加'
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showOverflowTooltip="true"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:images="{row}">
         <el-image
          v-if="row.images.length > 0"
          style="width: 60px; height: 60px"
          :src="row.images[0]"
          fit="cover"
          :preview-src-list="row.images"></el-image>
          <span v-else></span>
      </template>
      <!-- 登录账号角色只有管理员才有操作权限 -->
      <template v-slot:action="{row}">
        <el-button type="text" @click='handleSolve(row)' v-if="row.status == 1">处理</el-button>
        <span type="text"  v-if="row.status == 2">已处理</span>
      </template>
    </VTable>

  </div>
</template>

<script>
import VTable from '@/components/VTable';
import { mixinTable } from '@/mixins/table.js'
export default {
  name: 'ComplainList',
  components: {
    VTable,
  },
  mixins: [mixinTable],
  data() {
    return {
      field: [
        { name: "school_name", label: "学校名称", hidden: false },
        { name: "account", label: "反馈账号", hidden: false },
        { name: "company_name", label: "机构名称", hidden: false},
        { name: "content", label: "投诉内容", showTooltip: 'true', hidden: false},
        { name: "images", label: "图片", hidden: false},
        { name: "create_time", label: "投诉时间", hidden: false},
        { name: "action", label: "操作", width: "100", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          account: '',
          school_id: '',
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
      schoolOpt:[],
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    // 查询列表
    getTable() {
      this.$http.get('/admin/complaint/list', {params: this.table.params}).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list
          this.table.total = res.data.total;
          // 重置查询条件
          this.$refs.elFormDom.resetFields()
          this.table.params.page = 1;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 获取学校列表
    getSchool() {
      this.$http.get('/admin/school/list', {params: {page:1,count:10000}}).then(res => {
        if(res.code === 1) {
          this.schoolOpt = res.data.list;
        }
      })
    },
    // 处理
    handleSolve(row) {
      this.$confirm('确认标记已处理吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post('/admin/complaint/operation',{id: row.id}).then(res => {
          if(res.code == 1) {
            this.getTable();
          }
        })
      }).catch(() => {});
    },
  }
}
</script>